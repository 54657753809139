import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  NavLink,
  Navigate,
} from "react-router-dom";

import Loader from "./components/Loader";

import PrototypePage from "./pages/PrototypePage";
import IndexPage from "./pages/IndexPage";
import StatPage from "./pages/StatPage";
import ReportPage from "./pages/ReportPage";
import LoginPage from "./pages/LoginPage";

import Modals from "./components/Modals";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ErrorPage = () => <div />;

class App extends Modals {
  constructor(props) {
    super(props);
    this.loader = new Loader(this);
    this.tabs = Object.keys(props.access).filter(
      (url) => ["settings"].indexOf(url) == -1
    );
    console.log("this.tabs", this.tabs);
    console.log("authenticated", props.authenticated);
    this.custom_page = ["stat"];
    this.state.report = false;
    // this.tabs = ["sample", "sequence", "score"]; // .filter((t) => this.props.access[t]);
  }

  closeReport() {
    this.setState({ report: false });
  }

  renderLogin() {
    return <LoginPage />;
  }

  render() {
    if (!this.props.authenticated || this.props.authenticated == "guest") {
      return this.renderLogin();
    }

    return this.modalsWrapper(
      <BrowserRouter>
        {this.state.report ? (
          <Dialog
            open={true}
            onClose={() => this.closeReport()}
            maxWidth={"xl"}
            scroll={"paper"}
          >
            <DialogTitle>
              Report a problem
              <IconButton aria-label="close" onClick={() => this.closeReport()}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <ReportPage />
          </Dialog>
        ) : (
          ""
        )}

        <div className={"application"}>
          <ResponsiveAppBar
            tabs={this.tabs}
            access={this.props.access}
            authenticated={this.props.authenticated}
            title={"SoyDataHub"}
            that={this}
          />
          <div className={"app-content"}>
            <Routes>
              {this.tabs
                .filter((url) => this.custom_page.indexOf(url) == -1)
                .map((url) => (
                  <Route
                    key={url}
                    path={"/" + url}
                    element={
                      <PrototypePage
                        key={url}
                        that={this}
                        url={url}
                        page={this.props.access[url]}
                        authenticated={this.props.authenticated}
                        vt={this.props.vt}
                      />
                    }
                  />
                ))}

              <Route path="/stat" element={<StatPage />} />
              <Route path="" element={<Navigate to="/plant" replace />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
