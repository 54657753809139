import React from "react";
import Loader from "../components/Loader";
import { Alert, Button, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

class ReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.loader = new Loader(this);
    this.state = {
      index: 1,
      result: false,
      avail: false,
      GC: false,
      message: "",
    };
    this.ref = {};
  }

  componentDidMount() {}

  send() {
    const fd = new FormData();
    this.setState({ avail: false });
    fd.append("message", this.state.message);
    fd.append("GC", this.state.GC);
    this.loader.get("submit-report", fd, (url, data) => {
      this.setState({ result: "Your message have been sent. Thaks!" });
    });
  }

  render() {
    return (
      <>
        <div className="report-page">
          <div className="submit-form-messsage">
            <TextField
              fullWidth
              multiline
              rows={4}
              onChange={(e) => {
                this.setState({
                  message: e.target.value,
                  avail: e.target.value != "",
                });
              }}
              placeholder="If you find a problem, write to us about it!"
            />
            <div className="gc">
              <ReCAPTCHA
                sitekey="6LdL5BEqAAAAAClwGNsui0lQoXdYXWGHhD7c5j7P"
                onChange={(e) => {
                  this.setState({ GC: e });
                }}
              />
            </div>
            {this.state.result ? (
              <Alert>{this.state.result}</Alert>
            ) : (
              <Button
                onClick={() => this.send()}
                variant="contained"
                color="success"
                disabled={!this.state.avail || !this.state.GC}
              >
                Report a problem
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ReportPage;
