import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Records from "./Records";
import { Button } from "@mui/material";
import Editor from "../components/Editor";
import locale from "../i18n/locale.ru";

class RelationsTable extends Records {
  constructor(props) {
    super(props);
    this.state.page.limit = 9999;
    this.state.filter = [
      {
        col: { field: `${props.parentTable}_id`, type: "number" },
        operator: "=",
        value: this.props.sample.id,
      },
    ];
    this.table = props.table;
    this.fixed = { [`${props.parentTable}_id`]: props.sample.id };
  }

  modify(obj, read_only = false) {
    this.props.that.open(
      {
        title: read_only ? obj.code || locale.view : locale.edit,
        body: (
          <Editor
            table={this.table}
            read_only={read_only}
            close={() => this.that.close("mini")}
            header={this.state.header}
            that={this.props.that}
            target={obj}
            fixed={this.fixed}
          />
        ),
      },
      "mini",
      () => {
        this.load();
      }
    );
  }

  create() {
    this.props.that.open(
      {
        title: locale.create,
        body: (
          <Editor
            table={this.table}
            close={() => {
              this.that.close("mini");
              this.load();
            }}
            header={this.state.header}
            that={this.that}
            fixed={this.fixed}
          />
        ),
      },
      "mini",
      () => {
        // ...
      }
    );
  }

  render() {
    console.log("+", this.state.header, this.props.parentTable);
    return (
      <>
        <div className="header-line">
          <h3>{this.props.title}:</h3>
          <Button onClick={() => this.create()}>{locale.create}</Button>
        </div>
        <DataGrid
          rowHeight={28}
          columns={this.state.header.filter(
            (e) => e.field.split("__")[0] != this.props.parentTable
          )}
          rows={this.state.items}
          rowCount={this.state.total}
          pagination
          columnVisibilityModel={this.state.visibility}
          pageSizeOptions={[10, 20, 50, 100, 500]}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        />
      </>
    );
  }
}

export default RelationsTable;
