import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class DataTypes extends React.Component {
  constructor(props) {
    super(props);
    this.that = props.that;
    this.state = { types: [], create: {} };
    this.tree = props.that.props.target ? props.that.props.target.tree : false;

    if (!this.tree) return;
    let g = {};
    this.tree.map((v) => {
      if (!g[v.group]) g[v.group] = [];
      if (v.type == "date") v.value = dayjs(v.value).format("DD.MM.YYYY");
      g[v.group].push(v);
      this.state.create[v.group] = { type: "text", name: "" };
    });
    this.state.groups = g;
  }

  reload() {
    this.props.that.loader.get(
      "records",
      {
        page: { page: 1, limit: 9999 },
        table: "data_type",
      },
      (url, data) => {
        this.setState({ types: data["items"] });
      }
    );
  }

  componentDidMount() {
    if (this.state.groups) this.reload();
  }

  createType(G, gname) {
    let dt = this.state.create[gname];
    let exists = G[gname].filter(
      (v) => v.name.toLowerCase() == dt.name.toLowerCase()
    );
    if (exists.length > 0) {
      return console.log("Повтор", exists);
    }
    let dtdb = this.state.types.filter(
      (v) => v.group == gname && v.name.toLowerCase() == dt.name.toLowerCase()
    );
    if (dtdb.length) dt = dtdb[0];

    if (dt.id) {
      return this.setState((s) => {
        s.groups[gname].push(dt);
        return { groups: s.groups };
      });
    }
    dt.id = 0;
    dt.group = gname;
    this.props.that.loader.get(
      "update",
      { table: "data_type", sample: dt },
      (url, data) => {
        this.reload();
        this.setState((s) => {
          dt.id = data.id;
          s.groups[gname].push(dt);
          return { groups: s.groups };
        });
      }
    );
  }

  render() {
    let G = this.state.groups;
    if (!G) return "";
    let group_names = [
      ...new Set(
        this.state.types
          .map((v) => v.group)
          .filter((v) => Object.keys(G).indexOf(v) == -1)
      ),
    ];

    return (
      <>
        <div className="input-groups">
          {Object.keys(G).map((gname, gk) => {
            let options = this.state.types
              .filter((t) => t.group == gname)
              .map((t) => {
                t.label = t.name;
                return t;
              });
            return (
              <Accordion key={gk}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{gname}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="input-group">
                    {G[gname].length == 0 ? (
                      <span className="empty">
                        Нет параметров в этой группе
                      </span>
                    ) : (
                      ""
                    )}
                    {G[gname].map((one, k_one) => {
                      let fl = `__tree_${one.id}`;
                      let h = {
                        headerName: one.name,
                        field: fl,
                        type: one.type,
                        end: () => {
                          this.setState((s) => {
                            G[gname].splice(k_one, 1);
                            return { groups: G };
                          });
                          this.that.setState((s) => {
                            delete s.sample[fl];
                            return { sample: s.sample };
                          });
                        },
                      };
                      return this.that[`render_${h.type}`]
                        ? this.that[`render_${h.type}`](h)
                        : this.that.render_string(h);
                    })}
                  </div>
                  <div className="create-data-type">
                    <Autocomplete
                      size="small"
                      blurOnSelect
                      freeSolo
                      options={options}
                      onChange={(ev, ch) => {
                        this.setState((s) => {
                          s.create[gname] =
                            ch && ch.id ? ch : { type: "text", name: ch };
                          return { create: s.create };
                        });
                      }}
                      onInputChange={(ev, ch) => {
                        this.setState((s) => {
                          s.create[gname] =
                            ch && ch.id ? ch : { type: "text", name: ch };
                          return { create: s.create };
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Добавить параметр" />
                      )}
                    />
                    <TextField
                      select
                      size="small"
                      label="Тип данных"
                      value={this.state.create[gname].type}
                      disabled={this.state.create[gname].id ? true : false}
                      onChange={(e) => {
                        this.setState((s) => {
                          if (s.create[gname].id) return {};
                          s.create[gname].type = e.target.value;
                          return { create: s.create };
                        });
                      }}
                      variant="outlined"
                    >
                      {["text", "date", "number"].map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      variant="outlined"
                      onClick={() => this.createType(G, gname)}
                    >
                      Добавить
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>

        {this.state.types ? (
          <div className="add-groups-button">
            <Autocomplete
              size="small"
              blurOnSelect
              freeSolo
              options={group_names}
              onChange={(ev, ch) => {
                if (!ch) return;
                this.setState((s) => {
                  s.groups[ch] = [];
                  s.create[ch] = { type: "text", value: "" };
                  return { groups: s.groups, create: s.create };
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Добавить группу значений" />
              )}
            />
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default DataTypes;
