import * as React from "react";

import Loader from "../components/Loader";

window.basename = "/";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import locale from "../i18n/locale.ru";

const defaultTheme = createTheme();

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { res: undefined };
    this.prefix = "";
    this.loader = new Loader(this);
  }

  login(event) {
    console.log("event", event);
    event.preventDefault();
    const data = new FormData(event.target);
    this.loader.get(
      "/login",
      {
        login: data.get("login"),
        password: data.get("password"),
      },
      (url, data) => {
        if (data["error"]) this.setState({ res: data["error"] });
        if (data["success"]) window.location = "/";
      }
    );
  }

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <div
          style={{ backgroundImage: "url('/media/login_bg.jpg')" }}
          className="login-page"
        >
          <div className="login-form-wrapper">
            <img className="logo" src="./media/logo.png" />

            <div className="meta">
              <h1>
                Интеграция данных о фенотипах и генотипах сортов для оптимизации
                селекционных программ
              </h1>
              <p>
                SoyDataHub содержит информацию о сортах сои из коллекции
                Дальневосточного государственного аграрного университета
              </p>
            </div>

            <Box
              className="login-form"
              component="form"
              onSubmit={(e) => this.login(e)}
              noValidate
            >
              {this.state.res ? (
                <Alert severity={"error"}>{this.state.res}</Alert>
              ) : (
                ""
              )}
              <TextField
                size="small"
                fullWidth
                required
                label={locale.login}
                name="login"
                autoFocus
              />
              <TextField
                size="small"
                required
                fullWidth
                name="password"
                label={locale.password}
                type="password"
                autoComplete="current-password"
              />
              <Button fullWidth type="submit" variant="contained">
                {locale.log_in}
              </Button>
            </Box>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default LoginPage;
