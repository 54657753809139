// EN
const locale = {
  create: "Создать",
  delete: "Удалить",
  save: "Сохранить",
  close: "Закрыть",
  cancel: "Отмена",
  logout: "Выйти",
  view: "View",
  ok: "Ok",
  edit: "Edit",
  add_filter: "Фенотип",
  number: "Номер",
  string: "Стока",
  date: "Дата",
  export: "Экспортировать (.tsv)",
  import: "Имортировать (.tsv)",
  error_reading_file: "Ошибка импорта файла",
  import_success: "Успешно добалено в таблицу",
  log_in: "Войти",
  login: "Логин",
  password: "Пароль",
  removed_item: "Объект удалён",
  removed: "Объекты удалены",
};

export default locale;
