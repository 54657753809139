import React from "react";
import Loader from "../components/Loader";
import { BarChart, PieChart, pieArcLabelClasses } from "@mui/x-charts";
import Paper from "@mui/material/Paper";

import {
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.loader = new Loader(this);
    this.state = { hists: [], search: "", pheno: 0 };
    this.karyotype = React.createRef();
    this.timer = null;
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  handleWindowResize() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ width: window.innerWidth }, () => {});
      console.log("window.innerWidth", window.innerWidth);
    }, 250);
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth });
    this.loader.get("overview", {}, (url, data) => {
      this.setState({ hists: data.hists });
    });
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  search() {
    if (this.state.search.length === 0) return;
    location.href = "/plant?s=" + this.state.search;
  }

  render() {
    if (this.state.hists.length == 0) return "";
    let hist = this.state.hists[this.state.pheno];

    return (
      <>
        <div className="header-index">
          <img className="logo" src="./media/logo.png" />
          <h1>
            Интеграция данных о фенотипах и генотипах сортов для оптимизации
            селекционных программ
          </h1>
          <p>
            SoyDataHub содержит информацию о сортах сои из коллекции
            Дальневосточного государственного аграрного университета
          </p>

          <div className="quick-search">
            <Paper className="v-search" component="form">
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Быстрый поиск"
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    this.search();
                  }
                }}
              />
              <IconButton
                onClick={() => {
                  this.search();
                }}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
        </div>
        <div className="index-content">
          <h2>Анализ данных</h2>
          <div className="pheno-data">
            <div className="pheno-select">
              <FormControl className="pheno-select-form" fullWidth>
                <InputLabel>Фенотип</InputLabel>
                <Select
                  value={this.state.pheno}
                  onChange={(e) => {
                    this.setState({ pheno: e.target.value });
                  }}
                  label="Фенотип"
                >
                  {this.state.hists.map((hist, i) => (
                    <MenuItem key={hist.title} value={i}>
                      {hist.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="pheno-stat">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      {Object.keys(hist.stat).map((st) => (
                        <TableRow key={st}>
                          <TableCell>
                            <strong>{st}</strong>
                          </TableCell>
                          <TableCell align="right">{hist.stat[st]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <Paper className="pheno-result">
              <BarChart
                key={hist.title}
                dataset={hist.bar}
                borderRadius={3}
                height={330}
                width={520}
                xAxis={[{ scaleType: "band", dataKey: "k" }]}
                series={[
                  {
                    dataKey: "v",
                    //label: hist.title,
                  },
                ]}
              />
            </Paper>
          </div>
        </div>
      </>
    );
  }
}

export default IndexPage;
